.map {
  position: relative;
  width: 100%;
  padding: 1rem;

  @media (min-width: 768px) {
      display: block;
  }
}

.map-container-wrapper {
  position: relative;
  width: 100%;
}

.map-container {
  overflow: hidden;
  width: 100%;
  height: 300px;
  border-top: 1px solid #ddd;
  position: relative;
  border-radius: 8px;

  @media (min-width: 768px) {
    border: none;
    height: 600px;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.map-container img {
  height: auto;
  object-fit: cover;
  max-width: 100%;

  @media (min-width: 768px) {
    height: 100%;
  }
}
.map-card {
  background: #fff;
  padding: 1.5rem;
  width: 100%;
  order: 2;
  margin: 1rem auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 350px;
    order: 1;
    position: relative;
    flex-shrink: 0;
  }
}


.footer__block.footer__block--map {
  background-color: transparent;
}
