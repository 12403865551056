
#super-footer {
  @import 'base/module';
  @import 'components/module';
  @import 'layout/module';

  h1, h2, h3, h4, h5, h6 {
    color: #3d3d3d;
  }

  max-width: 1130px;
  margin: 0 auto;
}
