.about-us {
  text-align: center;
  position: relative;

  &__body {
    line-height: 2;
    overflow-y: auto;
    text-align: left;
  }

  &__body p {
    margin-bottom: 20px;
  }

  &__body p br {
    content: " ";
    display: block;
    margin-bottom: 1em;
  }

  &__read-more {
    display: block;
    margin: 20px 0;
    position: relative;
    text-decoration: none;
    z-index: 1;
  }

  @include breakpoint(768px) {
    padding: 50px;
  }
}

.about-us-background {
  display: block;
  min-width: 100%;
  height: 150px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  overflow: hidden;

  @include breakpoint(768px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100%;
    transform: scale(1.1);
    filter: blur(5px);
  }
}

.about-us-background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  @include breakpoint(768px) {
    height: 100%;
  }
}

.about-us-content {
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
  max-height: 540px;
  overflow: hidden;
  width: 80%;
  background: #fff;

  &:after {
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    bottom: 0;
    content: '';
    height: 2.5em;
    left: 0;
    position: absolute;
    width: 100%;
  }
}