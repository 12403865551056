.badge-tooltip {
  position: absolute;
  width: 300px;
  top: -142px;
  left: 61px;
  box-shadow: 1px 1px 6px #cacaca;
  border: 1px solid #cacaca;
  opacity: 0;

  &__header {
    color: #444;
    font-size: 1.3rem;
    padding: 7px 13px;
    background: #e8e8e8;
    border-bottom: 1px solid #cacaca;

    span {
      position: relative;
      top: 1px;

      @include breakpoint-mobile {
        font-size: 1rem;
      }
    }
  }

  &__view-link {
    @extend %view-link;
    margin-top: -5px;
    display: block;
  }

  &__content {
    padding: 10px;
    font-size: 1.2rem;
    background: #fff;
    color: #5d5d5d;

    @include breakpoint-mobile {
      font-size: 1rem;
    }
  }

  &__arrow {
    @include down-arrow(#c5c5c5, -16px);

    &--overlay {
      @include down-arrow(#fff, -10px);
    }
  }

  &__close {
    width: 1.2rem;
    float: right;
    margin-top: 3px;
    cursor: pointer;

    @include breakpoint-mobile {
      width: 1rem;
    }
  }

  @include breakpoint-mobile {
    width: 225px;
    top: -142px;
    left: 59px;
  }
}