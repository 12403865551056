.best-of-badge {
  font-family: var(--default-font);
  font-weight: 200 !important;
  display: flex;
  position: relative;
  justify-content: center;
  width: 287px;
  z-index: 9999999;

  &__image {
    align-self: center;
    width: 117px;
    cursor: pointer;
  }

  &__divider {
    height: 50px;
    width: 1px;
    background: #d6d6d6;
    align-self: center;
    margin: 0 10px;
  }

    &__stars {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: .5em;
    }
}
