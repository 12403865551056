.map-card {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    z-index: 9999999;
    text-align: center;
    border-radius: 8px;

    &__header {
        display: none;
        width: 100%;
        height: 200px;

        @include breakpoint(768px) {
            display: block;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        padding: 0 5px;
    }

    &__detail {
        display: flex;
        align-items: flex-start;
        padding: 20px 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        flex-wrap: nowrap;

        &:last-of-type {
            border-bottom: 0;
        }

        .location-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 25px;

            @include breakpoint(768px) {
                margin-left: 0;
            }
        }

        .map-card__name {
            margin: 0 0 5px 0;
            width: 100%;
            font-weight: 500;
        }

        .map-card__icon {
            flex-shrink: 0;
            display: flex;
            align-items: flex-start;
            width: 36px;
            padding-top: 5px;
            align-self: center;
        }

        img {
            margin: 0;
            flex-shrink: 0;
        }

        p {
            font-size: 14px;
            margin: 5px 25px;
            display: inline;
            flex-shrink: 1;

            @include breakpoint(768px) {
                display: block;
                margin: 5px 0;
                font-size: 12px;
                width: 100%;
                text-align: center;
            }
        }

        .location-container p {
            margin: 5px 0;
        }

        a {
            color: #3d3d3d;
        }

        .map-card__address {
            margin: 0;
            font-size: 14px;
            display: block;
        }

        @include breakpoint(768px) {
            display: block;
            padding: 5px;
            border: 0;
            text-align: center;

            .map-card__icon {
                width: 100%;
                justify-content: center;
                margin-bottom: 10px;
                padding-top: 0;
            }
        }
    }

    &__hours {
        margin-left: 0;

        @media (min-width: 768px) {
            margin: 0 auto;
            max-width: 33rem;

            .row {
                margin: 0;
            }
        }

        .row {
            margin: 0;
            padding: 0 25px;

            &:first-child {
                margin-top: -5px;
            }
        }

        p {
            margin: 0 10px;
            display: block;
        }

        .store-hours-days {
            padding-left: 0;
        }

        .store-hours-time {
            padding-right: 0;
        }
    }

    @include breakpoint(768px) {
        margin-top: 1.5rem;
        padding: 2rem;
    }
}
