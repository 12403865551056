@mixin breakpoint($width) {
  @media only screen and (min-width: $width) {
    @content
  }
}

@mixin breakpoint-mobile {
  @media only screen and (max-width: 414px) {
    @content
  }
}

@mixin down-arrow($color, $bottom) {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 16px solid $color;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  left: 21px;
  bottom: $bottom;
}