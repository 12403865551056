.best-of-counter {
  display: flex;
  position: relative;

  &__number-box {
    height: 48px;
    align-self: center;
    &__four-digits {
      width: 70px;
    }
    &__five-digits {
      width: 90px;
    }
  }

  &__number {
    text-decoration: none;
    color: #fff;
    position: absolute;
    width: 30px;
    margin-top: .2rem;
    align-self: center;
    right: 1rem;
    text-align: center;
    font-weight: 900 !important;

    span {
      font-weight: 900 !important;
    }

    &__four-digits {
      width: 50px;
    }
    &__five-digits {
      width: 70px;
    }
  }

  &__text {
    font-size: 1.2rem;
    align-self: center;
    margin-top: 3px;
    color: #777;
  }
}
