.delivery {
  padding: 20px;

  &__icon {
    display: block;
    padding: 20px;
    margin: 0 auto 25px auto;
    border: 1px solid #ddd;
    border-radius: 2px;

    @include breakpoint(768px) {
      margin: 0 15px 0 0;
      float: left;
    }
  }

  &__section {
    margin: 15px 0;
    padding: 0 10px;

    @include breakpoint(768px) {
      margin: 10px 0;
    }
  }

  &__header {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 600 !important;
    margin: 0 0 20px 0;
    text-align: center;

    @include breakpoint(768px) {
      text-align: left;
    }
  }

  &__content {
    max-height: 300px;
    overflow-y: scroll;
    font-size: 12px;
    color: #757575;

    > * {
      font-size: 12px;
    }

    @include breakpoint(768px) {
      height: 300px;
      //hide the inner scroll bar:
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__fade {
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );

    position: absolute;
    width: 100%;
    height: 1.5em;
    bottom: 0;
  }
}
