.footer {
  &__block {
    margin: 20px 0;
    background: #fff;
    width: 100%;

    &--about-owner {
      margin-top: 60px !important;
    }

    &--best-of-badge {
      margin-bottom: -48px !important;
    }

    @include breakpoint(768px) {
      margin: 20px auto;
    }
  }

  &__underline {
    margin: 15px auto;
    width: 75px;
    height: 1px;
    border-bottom: 1px solid #bbb;
  }
}

