%view-link {
  color: $pink;
  font-size: 1.2rem;
  font-weight: var(--bn--font-bold) !important;
  font-family: var(--default-font);

  svg {
    width: 1.6rem;
    position: relative;
    top: 0.75rem;
  }

  span {
    font-weight: var(--bn--font-bold) !important;
    font-size: 1.2rem;
  }

  &:hover {
    color: $magenta;
    fill: $magenta;
  }

  rect#med {
    fill: currentColor;
  }
}
