.social {
  padding: 20px;

  &__icons {
    margin: 50px 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__icon {
    float: left;
    width: 35%;
    margin: 10px 2.5%;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(768px) {
      width: auto;
      padding: 0 40px;
      margin: 10px 0;
      border-left: 1px solid #eee;

      &:last-of-type {
        border-right: 1px solid #eee;
      }
    }
  }

  &__icon-link {
    text-decoration: none !important;
  }

  i {
    font-size: 24px;
    opacity: .75;
    margin: 0 0 20px 0;
  }

    .social_logo {
        margin-bottom: 20px;
    }
}
