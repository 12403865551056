.about-owner {
  text-align: center;

  &__florist {
    font-size: 32px;
    margin: 0 0 20px 0;
  }

  &__header {
    min-height: 80px;
    position: relative;
  }

  &__image-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    border: 7px solid #fff;
    border-radius: 50%;

    &--placeholder {
      background: #777;
      top: -50px;
    }
  }

  &__image {
    border-radius: 50%;

    &--placeholder {
      color: #FFF;
      padding: 25px;
      border-radius: 0;
      border: none;
    }
  }

  &__content {
    padding: 0 20px 20px 20px;
    line-height: 2;
    text-align: left;

    @include breakpoint(768px) {
      max-width: 70%;
      margin: 0 auto;
      padding: 0 20px 50px 20px;
    }
  }
  &__content p {
    margin-bottom: 20px;
  }
  &__content p br {
    content: " ";
    display: block;
    margin-bottom: 1em;
  }
}
